<template>
  <el-scrollbar>
      <div @mouseenter="filterScroll(true)"
           @mouseleave="filterScroll(false)"
           class="filter-section">
          <div class="filter-section-container">
              <div class="mobile-header">
                  <div @click="$emit('close')" class="reset-container">
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.47656 1.53857L13.0003 13.0623" stroke="#182236" stroke-width="2"
                                stroke-linecap="round"/>
                          <path d="M1.47656 13.0625L13.0003 1.53876" stroke="#182236" stroke-width="2"
                                stroke-linecap="round"/>
                      </svg>
                      <span class="title">{{ $fn.tr('Filters') }}</span>
                  </div>
                  <div @click="resetAll" class="reset-container">
                      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M10.4865 10.5872C10.3652 10.4726 10.2052 10.4052
                          10.0367 10.3976C9.86797 10.3901 9.70236 10.4429
                          9.57094 10.5461C8.59456 11.2797 7.36595 11.617
                          6.14112 11.4879C4.91625 11.3587 3.78959 10.7729 2.99637
                           9.85286C2.20302 8.93278 1.80417 7.74937 1.8828 6.54938C1.96157
                           5.34923 2.51184 4.22497 3.41881 3.41115C4.32591 2.59733 5.51978
                            2.15675 6.75157 2.18111C7.98335 2.20549 9.15792 2.69297 10.0304
                            3.54206L8.69758 4.8431L13.502 5.96099L12.3567 1.27127L10.9947
                            2.60082C9.87343 1.50646 8.36227 0.877414 6.77724 0.845055C5.19192
                            0.812798 3.65516 1.37967 2.488 2.42731C1.32088 3.47491 0.613601
                             4.92238 0.514084 6.46696C0.41456 8.0117 0.930351 9.53417
                             1.95376 10.7163C2.97716 11.8986 4.429 12.649 6.00593 12.8109C7.58279
                             12.9726 9.16284 12.5334 10.4158 11.5849C10.5729 11.4682 10.6709 11.2909
                              10.6844 11.0986C10.6981 10.9063 10.6262 10.7175 10.4871 10.5807L10.4865 10.5872Z"
                              fill="#182236"/>
                      </svg>
                      <span class="title">{{ $fn.tr('Reset') }}</span>
                  </div>
              </div>
              <div class="image-container project-svg" v-html="projectImage"></div>
              <div class="filter-container">
                  <div class="up-container">
                      <el-select
                          ref="select"
                          @change="changeProject"
                          v-model="activeProject">
                          <el-option
                              v-for="item in projects"
                              :key="item.value"
                              :label="item.label"
                              :value="item.slug"
                          />
                      </el-select>
                      <div v-if="false" class="tags-container">
                          <el-tag v-for="item in apartmentCategory"
                                  @click="toggleCategoryTag(item)"
                                  size="large"
                                  :class="{active:item.active}"
                                  closable>{{ item.title }}
                          </el-tag>
                      </div>
                  </div>
                  <div class="select-containers">
                      <div class="item">
                          <div @click="typeCollapse = !typeCollapse"
                               :class="{active:typeCollapse}"
                               class="collapse-header">
                              <span>{{ $fn.tr('Apartment Type') }}</span>
                              <i class="el-icon el-collapse-item__arrow">
                                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                      <path fill="currentColor"
                                            d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736
                                        512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12
                                         0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592
                                         149.376a29.12 29.12 0 0 0-41.728 0z"></path>
                                  </svg>
                              </i>
                          </div>
                          <slide-up-down v-model="typeCollapse" :duration="400">
                              <el-checkbox-group v-model="activeApartmentTypes"
                                                 @change="changeFilters"
                                                 class="collapse-input-container">
                                  <el-checkbox v-for="(item,index) in filteredTypeTaxonomy"
                                               :key="index"
                                               :label="item.id">
                                      {{ item.title }}
                                  </el-checkbox>
                              </el-checkbox-group>
                          </slide-up-down>
                      </div>
                      <div class="item">
                          <div @click="blockCollapse = !blockCollapse"
                               :class="{active:blockCollapse}"
                               class="collapse-header">
                              <span>{{ $fn.tr('Block') }}</span>
                              <i class="el-icon el-collapse-item__arrow">
                                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                      <path fill="currentColor"
                                            d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736
                                        512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12
                                         0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592
                                         149.376a29.12 29.12 0 0 0-41.728 0z"></path>
                                  </svg>
                              </i>
                          </div>
                          <slide-up-down v-model="blockCollapse" :duration="400">
                              <el-checkbox-group v-model="activeApartmentBlocks"
                                                 @change="changeFilters"
                                                 class="collapse-input-container">
                                  <el-checkbox v-for="(item,index) in blocks"
                                               :key="index"
                                               :label="item.id">{{ item.title }}
                                  </el-checkbox>
                              </el-checkbox-group>
                          </slide-up-down>
                      </div>
                      <div class="item">
                          <div @click="floorCollapse = !floorCollapse"
                               :class="{active:floorCollapse}"
                               class="collapse-header">
                              <span>{{ $fn.tr('Floor') }}</span>
                              <i class="el-icon el-collapse-item__arrow">
                                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                      <path fill="currentColor"
                                            d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736
                                        512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12
                                         0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592
                                         149.376a29.12 29.12 0 0 0-41.728 0z"></path>
                                  </svg>
                              </i>
                          </div>
                          <slide-up-down v-model="floorCollapse" :duration="400">
                              <el-checkbox-group v-model="activeApartmentFloors"
                                                 @change="changeFilters"
                                                 class="collapse-input-container">
                                  <el-checkbox v-for="(item,index) in floors"
                                               :key="index"
                                               :label="Number(item.title)">{{
                                          item.title
                                      }}
                                  </el-checkbox>
                              </el-checkbox-group>
                          </slide-up-down>
                      </div>
                  </div>
                  <div class="ranges-container">
                      <div class="item">
                          <span class="title">{{ $fn.tr('Area Range') }}</span>
                          <div class="slider-inner">
                              <el-slider v-model="areaRange"
                                         range
                                         @change="changeArea"
                                         :show-tooltip="false"
                                         :max="areaRangeMax"/>
                              <span class="text min fira">{{ areaRange[0] }} M<sup>2</sup></span>
                              <span class="text max fira">{{ areaRange[1] }} M<sup>2</sup></span>
                          </div>
                      </div>
                      <div class="item">
                          <span class="title">{{ $fn.tr('Price Range') }}</span>
                          <!--                        <Slider v-model="priceRange"-->
                          <!--                                :format="priceFormat"-->
                          <!--                                :max="priceMax"-->
                          <!--                                :step="1000"-->
                          <!--                                @change="changePrice"-->
                          <!--                        />-->
                          <div class="slider-inner">
                              <el-slider v-model="priceRange"
                                         range
                                         @change="changePrice"
                                         :show-tooltip="false"
                                         :max="priceMax"/>
                              <span class="text min fira">{{ priceRange[0] }} {{ priceFormat }}</span>
                              <span class="text max fira">{{ priceRange[1] }} {{ priceFormat }}</span>
                          </div>
                      </div>
                  </div>
                  <div @click="resetAll" class="reset-container">
                      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M10.4865 10.5872C10.3652 10.4726 10.2052 10.4052
                          10.0367 10.3976C9.86797 10.3901 9.70236 10.4429
                          9.57094 10.5461C8.59456 11.2797 7.36595 11.617
                          6.14112 11.4879C4.91625 11.3587 3.78959 10.7729 2.99637
                           9.85286C2.20302 8.93278 1.80417 7.74937 1.8828 6.54938C1.96157
                           5.34923 2.51184 4.22497 3.41881 3.41115C4.32591 2.59733 5.51978
                            2.15675 6.75157 2.18111C7.98335 2.20549 9.15792 2.69297 10.0304
                            3.54206L8.69758 4.8431L13.502 5.96099L12.3567 1.27127L10.9947
                            2.60082C9.87343 1.50646 8.36227 0.877414 6.77724 0.845055C5.19192
                            0.812798 3.65516 1.37967 2.488 2.42731C1.32088 3.47491 0.613601
                             4.92238 0.514084 6.46696C0.41456 8.0117 0.930351 9.53417
                             1.95376 10.7163C2.97716 11.8986 4.429 12.649 6.00593 12.8109C7.58279
                             12.9726 9.16284 12.5334 10.4158 11.5849C10.5729 11.4682 10.6709 11.2909
                              10.6844 11.0986C10.6981 10.9063 10.6262 10.7175 10.4871 10.5807L10.4865 10.5872Z"
                              fill="#182236"/>
                      </svg>
                      <span class="title">{{ $fn.tr('Reset') }}</span>
                  </div>
              </div>
          </div>
      </div>
  </el-scrollbar>
</template>

<script setup>
import {
  ElCollapse, ElCollapseItem,
  ElCheckbox, ElCheckboxGroup,
  ElSelect, ElOption, ElTag, ElSlider, ElScrollbar
} from 'element-plus';
import { computed, inject, nextTick, onBeforeMount, onMounted, ref, watch } from "vue";
import { useMenuStore } from "../../../../store/pinia/menu";
import { useProjects } from "../../../../store/pinia/projects";
import { useRoute, useRouter } from "vue-router";
import SlideUpDown from 'vue3-slide-up-down';

let store = useMenuStore();
const router = useRouter();
const route = useRoute();
let projectStore = useProjects();
let props = defineProps({
  projects: {
      type: Array,
      required: true
  }
});

let typeCollapse = ref(false);
let blockCollapse = ref(false);
let floorCollapse = ref(false);
let siteSettings = computed(() => store.indx.siteSettings || {});
let areaFormat = {
  suffix: 'M<sup>2</sup>',
};
let priceFormat = computed(() => {
  return projectStore.currency.code === 'USD' ? '$' : 'gel';
});

const {
  apartmentTypes,
  blocks,
  floors,
  typeTaxonomy,
  parseRouteValue
} = inject('project');

const filteredTypeTaxonomy = computed(() => {
  const filterApartmentTypes = apartmentTypes.value.filter(item => item.project_id === activeProject.value.id);

return typeTaxonomy.value?.filter(taxonomy => {
  // Check if the taxonomy id is present in any apartmentType's taxonomies array
  return filterApartmentTypes?.some(apartment => apartment.taxonomies?.includes(taxonomy.id));
});
});
let activeProject = computed(() => projectStore.activeProject);
let projectImage = computed(() => activeProject.value?.render2?.tabs?.[0]?.render_svg);
let apartmentCategory = ref([]);
let activeApartmentTypes = ref([]);
let activeApartmentBlocks = ref([]);
let activeApartmentFloors = ref([]);
let areaRange = ref([0, 0]);
let areaRangeMax = computed(() => Number(siteSettings.value.max_area));
let usd = computed(() => store?.indx.usd?.usd);
let priceMax = computed(() => {
  let price = projectStore.currency?.code === 'USD' ? Number(siteSettings.value.max_price_usd) :
      Number(siteSettings.value.max_price_gel);
  priceRange.value = [0, price];
  return price;
});
let priceRange = ref([0, 0]);
let isQueryParametersChange = computed(() => projectStore.getIsQueryParameters);
let activeApartmentCategories = computed(() => apartmentCategory.value?.filter(item => item.active)?.map(item => item.id));
const emit = defineEmits(['change']);

const changeArea = async (e) => {
  projectStore.setQueryParameters({
      activeApartmentTypes: activeApartmentTypes.value,
      activeApartmentBlocks: activeApartmentBlocks.value,
      activeApartmentFloors: activeApartmentFloors.value,
      activeApartmentCategories: activeApartmentCategories.value,
      areaRange: e,
      priceRange: priceRange.value,
  });
  await projectStore.getFlats();
  emit('change');
  updateScroll();
};
const changePrice = async (e) => {
  emit('change');
  projectStore.setQueryParameters({
      activeApartmentTypes: activeApartmentTypes.value,
      activeApartmentBlocks: activeApartmentBlocks.value,
      activeApartmentFloors: activeApartmentFloors.value,
      activeApartmentCategories: activeApartmentCategories.value,
      areaRange: areaRange.value,
      priceRange: e,
  });
  await projectStore.getFlats();
  updateScroll();
};
const setRouteQueryParameters = () => {
  router.push({
      path: route.path,
      query: {
          'type': activeApartmentTypes.value,
          'floor': activeApartmentFloors.value,
          'block': activeApartmentBlocks.value,
          'category': activeApartmentCategories.value
      },
  });
};
const changeFilters = () => {
  emit('change');
  getFlats();
};
const getFlats = async () => {
  setRouteQueryParameters();
  projectStore.setQueryParameters({
      activeApartmentTypes: activeApartmentTypes.value,
      activeApartmentBlocks: activeApartmentBlocks.value,
      activeApartmentFloors: activeApartmentFloors.value,
      activeApartmentCategories: activeApartmentCategories.value,
      areaRange: areaRange.value,
      priceRange: priceRange.value,
  });
  await projectStore.getFlats();
  updateScroll();
};
const setApartmentCategory = () => {
  apartmentCategory.value = store.indx?.terms?.category?.map(item => ({
      ...item,
      active: false
  }))?.filter(item => item.show_in_filter?.value === '1');
};
const toggleCategoryTag = (tag) => {
  apartmentCategory.value?.forEach(item => {
      if (item.id === tag.id) item.active = !item.active;
  });
  getFlats();
};
const setQueryVariables = () => {
  activeApartmentTypes.value = parseRouteValue(route.query?.type)
  activeApartmentBlocks.value = parseRouteValue(route.query?.block)
  activeApartmentFloors.value = parseRouteValue(route.query?.floor)
  setTimeout(() => {
      
      if (Object.keys(activeApartmentTypes.value).length !== 0) typeCollapse.value = true
      if (Object.keys(activeApartmentBlocks.value).length !== 0) blockCollapse.value = true
      if (Object.keys(activeApartmentFloors.value).length !== 0) floorCollapse.value = true
  }, 1500);


  apartmentCategory.value?.forEach(item => {
      if (route.query?.category?.includes(`${ item.id }`)) item.active = true;
  });
};
const setRanges = () => {
  // areaRangeMax = Number(siteSettings.value.max_area)
  // priceMax = projectStore.currency?.code === 'USD' ? Number(siteSettings.value.max_price_usd) :
  //     Number(siteSettings.value.max_price_gel)

  areaRange.value = [0, Number(siteSettings.value.max_area)];

  priceRange.value = projectStore.currency?.code === 'USD' ?
      [0, Number(siteSettings.value.max_price_usd)] : [0, Number(siteSettings.value.max_price_gel)];
};
const resetAll = () => {
  activeApartmentTypes.value = [];
  activeApartmentBlocks.value = [];
  activeApartmentFloors.value = [];
  apartmentCategory.value?.map(item => item.active = false);
  //changed to connect
  setRanges();
  router.push({
      path: route.path,
      query: ''
  });
  getFlats();
};
const changeProject = (item) => {
  router.push({
      path: `/projects/${ item }/apartments`,
      query: '',
  });
};
const filterScroll = (value) => {
  if (window.innerWidth < 1024) return;
  store.disableScrollStatus = value;
};
const updateScroll = () => {
  setTimeout(() => {
      store.triggerUpdate = !store.triggerUpdate;
  }, 300);
};

watch(isQueryParametersChange, () => {
  setQueryVariables();
});
watch(route, (to) => {
  setQueryVariables();
}, { flush: 'pre', immediate: true, deep: true });

const select = ref('');
const scroller = store.Loco;
const scrollEvent = () => {
scroller?.on("scroll", (e) => {
  select.value?.blur();
});

};

onMounted(async () => {
  setRanges();
setApartmentCategory();
await nextTick();
setQueryVariables();
scrollEvent();
});

</script>

<style lang="scss" scoped>
.el-scrollbar:deep {
  height: calc(100vh - 265px);
  overflow: unset;
  @media only screen and (max-width: 1023px) {
      display: none;
  }

  .el-scrollbar__bar {
      width: 4px;
      right: -1px;

      .el-scrollbar__thumb {
          background: #940128;
          opacity: 1;
      }
  }
}

.filter-section {
  //height: calc(100vh - 265px);
  //overflow-y: auto;
  width: 574px;
  border-right: 2px solid rgba(140, 140, 140, 0.2);
  padding: 0 100px 30px 50px;
  color: $primaryNavy;
  @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      width: 400px;
      padding: 0 50px 0 30px;
  }
  @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      width: 350px;
      padding: 0 30px 0 15px;
  }

  @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0 30px;
  }

  &::-webkit-scrollbar {
      display: none;
  }

  .image-container:deep {
      svg {
          width: 100%;

          path, polygon {
              fill: transparent;
              transition: all .45s ease-out;

              &.active {
                  fill: $burgundy;
              }
          }
      }
  }

  .title {
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-family: FiraGO;
      display: block;
  }

  .up-container:deep {
      margin-top: 34px;

      .el-select {
          width: 100%;

          .el-input__wrapper {
              box-shadow: unset !important;
              padding: 0 0 13px 0;
              border-radius: unset;
              border-bottom: 2px solid rgba(24, 34, 54, 0.2);
              background: transparent;

              .el-input__inner {
                  font-family: 'FiraGO';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  color: $primaryNavy;
                  letter-spacing: 0.1em;
                  text-transform: uppercase;
                  -moz-font-feature-settings: "case";
                  -webkit-font-feature-settings: "case";
                  font-feature-settings: "case" on;
              }
          }

      }

  }

  .tags-container:deep {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 30px;

      .el-tag {
          background: rgba(24, 34, 54, 0.5);
          border-radius: 5px;
          margin-right: 8px;
          margin-bottom: 8px;
          cursor: pointer;
          box-shadow: none;
          border: unset;
          transition: all .45s ease-out;

          &.active {
              background: $primaryNavy;

          }

          .el-tag__content {
              font-family: 'FiraGO';
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              color: #FFFFFF;
          }

          .el-icon {
              color: white;

              &:hover {
                  background: transparent;
              }
          }
      }
  }

  .select-containers:deep {
      margin-top: 34px;

      .title {
          margin-bottom: 21px;
      }

      .item {
          margin-top: 34px;

          &:first-child {
              margin-top: 0;
          }
      }

      .collapse-header {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          margin-bottom: 15px;
          width: 100%;
          font-weight: 700;
          font-size: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          font-family: 'FiraGO';
          border-bottom: 2px solid rgba(24, 34, 54, 0.1);
          background: transparent;

          &.active {
              .el-icon {
                  transform: rotate(90deg);
              }
          }

          .el-icon {
              transition: all .45s ease-out;
          }
      }

      .el-collapse {
          border: none;
          background: transparent;

          .collapse-header {
              font-weight: 700;
              font-size: 13px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: $primaryNavy;
              font-family: 'FiraGO';
              border-bottom: 2px solid rgba(24, 34, 54, 0.1);
              background: transparent;
          }

          .el-collapse-item__wrap {
              border: unset;
              background: transparent;

              .el-collapse-item__content {
                  padding-bottom: 0;
                  padding-top: 20px;
              }
          }

      }

      .el-checkbox {
          margin-right: 0;
          flex-basis: 50%;

          .el-checkbox__input.is-checked {
              .el-checkbox__inner {
                  &:after {
                      transform: scale(1) translate(-50%, -50%);
                  }
              }
          }

          .el-checkbox__inner {
              border-radius: unset;
              border: 1px solid $primaryNavy;
              width: 19px;
              height: 19px;
              background: transparent !important;

              &:after {
                  background: $burgundy;
                  border-radius: unset;
                  width: 12px;
                  height: 12px;
                  transform: scale(0) translate(-50%, -50%);
                  top: 50%;
                  left: 50%;
                  border: unset;
              }
          }

          .el-checkbox__label {
              font-family: 'FiraGO';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: $primaryNavy;
          }
      }

      .collapse-input-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
      }
  }

  .ranges-container :deep {
      margin-top: 54px;

      .title {
          margin-bottom: 54px;
      }

      .item {
          &:first-child {
              margin-bottom: 34px;
          }
      }

      .slider-inner {
          position: relative;

          .text {
              font-weight: 700;
              font-size: 16px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              position: absolute;
              top: -20px;

              &.min {
                  left: 0;
              }

              &.max {
                  right: 0;
              }
          }
      }

      .el-slider {

          .el-slider__bar {
              height: 5px;
              background: $burgundy;
              border-radius: unset;
          }

          .el-slider__runway {
              border-radius: unset !important;
              height: 5px !important;
              background: rgba(24, 34, 54, 0.2) !important;
          }

          .slider-tooltip {
              background: unset !important;
              font-family: 'FiraGO';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              letter-spacing: 0.1em;
              color: $primaryNavy;
              text-transform: uppercase;
              transform: scale(1) translate(-50%, -50%) !important;
              border: none;
              padding: 0;

              &:before {
                  display: none !important;
              }

          }

          .el-slider__button {
              border-radius: unset;
              background: $burgundy;
              border: unset;
              width: 5px;
              height: 25px;
              top: -9.5px;
              right: 0;
              box-shadow: unset;
          }
      }
  }

  .reset-container {
      margin-top: 55px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .title {
          margin-left: 8px;
      }
  }

  .mobile-header {
      display: none;
      padding-bottom: 34px;
      border-bottom: 2px solid rgba(24, 34, 54, 0.2);
      @media only screen and (max-width: 1023px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
      }

      .reset-container {
          display: flex;
      }
  }
}
</style>
<style lang="scss">
body {
  &.is-dark {
      .filter-section {
          color: white;
      }

      .reset-container {
          svg {
              path {
                  fill: white !important;
              }
          }
      }

      .up-container {
          .el-icon svg {
              path {
                  fill: white;
              }
          }

          .el-input__wrapper {
              border-color: rgba(255, 255, 255, .2) !important;
          }
      }

      .mobile-header {
          path {
              stroke: white !important;
          }
      }

      .el-collapse-item__header, .el-checkbox__label, .slider-tooltip {
          color: white !important;
      }

      .el-checkbox__inner {
          border: 1px solid white !important;
      }

      .el-input__inner {
          color: white !important;
      }
  }
}
</style>


<style src="@vueform/slider/themes/default.css"></style>

